<template>
    <div>
        <div class="my-stake-table card" v-if="stakes.length">
            <UnstakeTable
                :rows="stakes"
                :pageCount="pageCount"
                :page="page"
                @update:page="value => page = value"
                :selected="selected"
                @update:selected="value => selected = value"
                :pageSize="pageSize"
            ></UnstakeTable>
            
            <div class="my-stake-table__footer-wrap">
                <ul class="my-stake-table__btns-list">
                    <li>
                        <btn
                            @click="selected = []"
                            class="my-stake-table__btn button--gradient"
                        >
                            CANCEL SELECTION
                        </btn>
                    </li>
                    <li>
                        <btn
                            @click="unstake"
                            class="my-stake-table__btn button--gradient"
                        >
                            UNSTAKE SELECTED
                        </btn>
                    </li>
                </ul>
            </div>
        </div>
        <p v-else class="my-stake-table__no-data">You don’t have anything that can be unstaked</p>
    </div>
</template>

<script>
import UnstakeTable from '@/components/staking/UnstakeTable.vue';
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'MyUnstakeTable',
    components: {
        UnstakeTable
    },
    watch: {
        page: {
            immediate: true,
            handler(value) {
                this.getStakes({
                    page: value,
                    pageSize: this.pageSize,
                    type: 'unstakble'
                });
            }
        }
    },
    methods: {
        ...mapActions('stakes', ['getStakes']),
        ...mapActions('contract', ['unstakeArray']),
        async unstake() {
            await this.unstakeArray(this.selected);
            this.selected = [];
        }
    },
    computed: {
        ...mapGetters('stakes', ['stakes', 'count']),
        pageCount() {
            return Math.ceil(this.count / this.pageSize);
        }
    },
    data() {
        return {
            page: 1,
            pageSize: 25,
            selected: [],
        }
    }
}
</script>

<style lang="scss" scoped>
    .my-stake-table {
        max-width: 800px;
        margin: 0 auto;

        &__table {
            width: 100%;
        }

        &__body {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        &__thead {
            padding-left: 40px;
            padding-right: 40px;
            margin-bottom: 20px;
        }

        &__thead-tr {
            display: flex;
            gap: 20px;
        }

        &__thead-th {
            display: flex;
            font-family: 'Raleway';
            font-weight: 800;
            font-size: 24px;
            line-height: 117%;
            text-transform: uppercase;
            color: #ffffff;

            &--id {
                width: 100px;
            }

            &--checkbox {
                display: flex;
                gap: 10px;
                align-items: center;
                width: 100px;
            }

            &--time {
                width: 135px;
            }

            &--type {
                display: flex;
                width: 275px;
            }
        }

        &__bg {
            padding: 40px;
            backdrop-filter: blur(7px);
            background-color: rgba(255, 255, 255, 0.1);
            box-shadow: 3px 4px 4px 0px rgba(255, 255, 255, 0.20) inset,
                0px 4px 4px rgba(0, 0, 0, 0.25),
                0px 1px 4px rgba(0, 55, 96, 0.15);
            border-radius: 5px;
        }

        &__tr {
            display: flex;
            gap: 20px;
        }

        &__td {
            font-family: 'Tektur';
            font-weight: 500;
            font-size: 18px;
            line-height: 130%;
            text-transform: uppercase;
            color: #ffffff;

            &--id {
                width: 100px;
            }

            &--time {
                width: 135px;
            }

            &--type {
                display: flex;
                width: 275px;
            }

            &--mobile {
                display: none;
            }

            &--checkbox {
                width: 100px;
            }
        }

        &__pagination {
            justify-content: flex-end;
            cursor: pointer;
        }

        &__btns-list {
            display: flex;
            gap: 25px;
            justify-content: center;
        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-width: 200px;
            min-height: 48px;
            padding: 10px 20px;

            font-family: "Tektur";
            font-size: 18px;
            font-weight: 500;
            line-height: 22.5px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }

        &__footer-wrap {
            margin-top: 30px;
        }

        &__no-data {
            font-family: "Tektur";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            color: #ffffff;
        }
    }

    @media screen and (max-width: ($bp_desktop - 1px)) {
        .my-stake-table {
            &__thead {
                padding-left: 10px;
                padding-right: 10px;
            }

            &__thead-tr {
                gap: 10px;
            }

            &__thead-th {
                flex: 1 1 0;
                justify-content: center;
                min-width: unset;
                font-size: 18px;

                &--id {
                    width: unset;
                }

                &--time {
                    width: unset;
                }

                &--checkbox {
                    width: unset;
                }
            }

            &__tr {
                gap: 10px;
            }

            &__td {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1 1 0;
                justify-content: center;
                font-size: 16px;
                text-align: center;

                &--id {
                    width: unset;
                }

                &--checkbox {
                    width: unset;
                }
            }

            &__bg {
                padding: 10px;
            }

            &__btns-list {
                justify-content: center;
                gap: 15px;
            }

            &__btn {
                min-width: 130px;
                min-height: 40px;
                font-size: 16px;
            }

            &__input-wrap {
                min-width: 63px;
                display: flex;
                justify-content: flex-start;
            }

            &__no-data {
                font-size: 18px;
            }
        }
    }

    @media screen and (max-width: ($bp_mb - 1px)) {
        .my-stake-table {
            &__body {
                gap: 0;
            }

            &__thead {
                padding: 0;
            }

            &__thead-tr {
                gap: 7px;
            }

            &__thead-th {
                flex: 1 1 0;
                justify-content: center;
                min-width: unset;
                font-size: 16px;

                &--type {
                    display: none;
                }

                &--id {
                    width: unset;
                }

                &--time {
                    width: unset;
                }
            }

            &__tr {
                gap: 7px;
            }

            &__td {
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 50px;
                flex: 1 1 0;
                justify-content: center;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                padding-top: 10px;
                padding-bottom: 10px;

                &--type {
                    display: none;
                }

                &--mobile {
                    display: flex;
                }

                &--id {
                    width: unset;
                    border-left: 1px solid #6B7B98;
                    border-right: 1px solid #6B7B98;
                }
            }

            &__bg {
                padding: 0;
                background-color: unset;
                box-shadow: none;
                backdrop-filter: none;
                border-radius: 0;
                border-top: 1px solid #6B7B98;
            }

            &__pagination {
                justify-content: center;
            }

            &__btn {
                min-width: 96px;
                min-height: 36px;
                padding: 4px 4px;

                font-size: 14px;
                line-height: 17.5px;
            }

            &__input-wrap {
                min-width: 63px;
            }

            &__no-data {
                font-size: 16px;
            }
        }
    }
    
    .card-footer {
        color: white;
    }
</style>
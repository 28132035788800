// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pagination {\n  width: -moz-fit-content;\n  width: fit-content;\n  color: #6B7B98;\n  display: flex;\n  gap: 0.5em;\n}\n.pagination-page-item, .pagination-prev-class, .pagination-next-class {\n  padding: 0.5em;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n  font-weight: 600;\n}\n.pagination-active-item a {\n  color: #FFCC13;\n}\n.pagination .disabled a {\n  color: gray;\n  border-color: gray;\n  cursor: default;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-stake-table card" }, [
    _c("div", { staticClass: "my-stake-table__thead card-header" }, [
      _c("div", { staticClass: "my-stake-table__thead-tr" }, [
        _c(
          "th",
          {
            staticClass:
              "my-stake-table__thead-th my-stake-table__thead-th--checkbox",
          },
          [
            _c("Checkbox", {
              staticClass: "filters-checkbox filters-checkbox--staking",
              attrs: {
                value: _vm.selected.length === _vm.pageSize,
                name: "checkboxAll",
              },
              on: { input: (value) => _vm.toggleAll(value) },
            }),
            _vm._v(" All "),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "my-stake-table__thead-th my-stake-table__thead-th--id",
          },
          [_vm._v(" Stake ")]
        ),
        _c(
          "div",
          {
            staticClass:
              "my-stake-table__thead-th my-stake-table__thead-th--type",
          },
          [_vm._v(" Type ")]
        ),
        _c("div", { staticClass: "my-stake-table__thead-th" }, [
          _vm._v(" Price "),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "my-stake-table__bg" }, [
      _c("table", { staticClass: "my-stake-table__table" }, [
        _c(
          "tbody",
          { staticClass: "my-stake-table__body card-body" },
          _vm._l(_vm.rows, function (row, index) {
            return _c("tr", { key: index, staticClass: "my-stake-table__tr" }, [
              _c(
                "td",
                {
                  staticClass:
                    "my-stake-table__td my-stake-table__td--checkbox",
                },
                [
                  _c(
                    "div",
                    { staticClass: "my-stake-table__input-wrap" },
                    [
                      _c("Checkbox", {
                        staticClass:
                          "filters-checkbox filters-checkbox--staking",
                        attrs: {
                          value: _vm.isSelected(row.stake_id),
                          name: row.stake_id.toString(),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.toggleSelected(row.stake_id)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "td",
                { staticClass: "my-stake-table__td my-stake-table__td--id" },
                [_c("div", [_vm._v("#" + _vm._s(row.stake_id))])]
              ),
              _c(
                "td",
                { staticClass: "my-stake-table__td my-stake-table__td--type" },
                [_vm._v(_vm._s(row.stake_type))]
              ),
              _c("td", { staticClass: "my-stake-table__td" }, [
                _vm._v(_vm._s(_vm.formattedPrize(row.token_amount)) + " MF"),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "card-footer" },
      [
        _c("Pagination", {
          attrs: { pageCount: _vm.pageCount, currentPage: _vm.page },
          on: { clickHandler: _vm.selectPage },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="my-stake-table card">
        <div class="my-stake-table__thead card-header">
            <div class="my-stake-table__thead-tr">
                <th class="my-stake-table__thead-th my-stake-table__thead-th--checkbox">
                    <Checkbox
                        :value="selected.length === pageSize"
                        @input="value => toggleAll(value)"
                        name="checkboxAll"
                        class="filters-checkbox filters-checkbox--staking"
                    />
                    All
                </th>
                <div class="my-stake-table__thead-th my-stake-table__thead-th--id">
                    Stake
                </div>
                <div class="my-stake-table__thead-th my-stake-table__thead-th--type">
                    Type
                </div>
                <div class="my-stake-table__thead-th">
                    Price
                </div>
            </div>
        </div>
        <div class="my-stake-table__bg">
            <table class="my-stake-table__table">
                <tbody class="my-stake-table__body card-body">
                    <tr
                        v-for="(row, index) in rows"
                        :key="index"
                        class="my-stake-table__tr"
                    >
                        <td class="my-stake-table__td my-stake-table__td--checkbox">
                            <div class="my-stake-table__input-wrap">
                                <Checkbox
                                    :value="isSelected(row.stake_id)"
                                    @input="toggleSelected(row.stake_id)"
                                    :name="row.stake_id.toString()"
                                    class="filters-checkbox filters-checkbox--staking"
                                />
                            </div>
                        </td>
                        <td class="my-stake-table__td my-stake-table__td--id">
                            <div>#{{ row.stake_id }}</div>
                        </td>
                        <td class="my-stake-table__td my-stake-table__td--type">{{ row.stake_type }}</td>
                        <td class="my-stake-table__td">{{ formattedPrize(row.token_amount) }} MF</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer">
            <Pagination
                :pageCount="pageCount"
                :currentPage="page"
                @clickHandler="selectPage"
            />
        </div>
        
    </div>
</template>

<script>
import Checkbox from "@/components/common/checkbox";
import Pagination from '../common/pagination.vue';

export default {
    name: 'MyUnstakeTable',
    components: {
        Checkbox,
        Pagination
    },
    props: {
        rows: Array,
        pageCount: Number,
        page: Number,
        selected: Array,
        pageSize: Number
    },
    methods: {
        selectPage(value) {
            this.$emit('update:page', value);
        },
        formattedPrize(value) {
            return this.toLocaleFormat((+value).toFixed(2));
        },
        toggleSelected(id) {
            if (this.selected.includes(id)) {
                this.$emit('update:selected', this.selected.filter(item => item !== id));
            } else {
                this.$emit('update:selected', [...this.selected, id]);
            }
        },
        isSelected(id) {
            return this.selected.includes(id);
        },
        toggleAll(value) {
            if (value) {
                this.$emit('update:selected', this.rows.map(item => item.stake_id));
            } else {
                this.$emit('update:selected', []);
            }
        }
    },
    watch: {
        rows() {
            this.$emit('update:selected', []);
        }
    }
}
</script>

<style lang="scss" scoped>
    .my-stake-table {
        max-width: 800px;
        margin: 0 auto;

        &__table {
            width: 100%;
        }

        &__body {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        &__thead {
            padding-left: 40px;
            padding-right: 40px;
            margin-bottom: 20px;
        }

        &__thead-tr {
            display: flex;
            gap: 20px;
        }

        &__thead-th {
            display: flex;
            font-family: 'Raleway';
            font-weight: 800;
            font-size: 24px;
            line-height: 117%;
            text-transform: uppercase;
            color: #ffffff;

            &--id {
                width: 100px;
            }

            &--checkbox {
                display: flex;
                gap: 10px;
                align-items: center;
                width: 100px;
            }

            &--time {
                width: 135px;
            }

            &--type {
                display: flex;
                width: 275px;
            }
        }

        &__bg {
            padding: 40px;
            backdrop-filter: blur(7px);
            background-color: rgba(255, 255, 255, 0.1);
            box-shadow: 3px 4px 4px 0px rgba(255, 255, 255, 0.20) inset,
                0px 4px 4px rgba(0, 0, 0, 0.25),
                0px 1px 4px rgba(0, 55, 96, 0.15);
            border-radius: 5px;
        }

        &__tr {
            display: flex;
            gap: 20px;
        }

        &__td {
            font-family: 'Tektur';
            font-weight: 500;
            font-size: 18px;
            line-height: 130%;
            text-transform: uppercase;
            color: #ffffff;

            &--id {
                width: 100px;
            }

            &--time {
                width: 135px;
            }

            &--type {
                display: flex;
                width: 275px;
            }

            &--mobile {
                display: none;
            }

            &--checkbox {
                width: 100px;
            }
        }

        &__pagination {
            justify-content: flex-end;
            cursor: pointer;
        }

        &__btns-list {
            display: flex;
            gap: 25px;
            justify-content: center;
        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-width: 200px;
            min-height: 48px;
            padding: 10px 20px;

            font-family: "Tektur";
            font-size: 18px;
            font-weight: 500;
            line-height: 22.5px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }

        &__footer-wrap {
            margin-top: 30px;
        }
    }

    @media screen and (max-width: ($bp_desktop - 1px)) {
        .my-stake-table {
            &__thead {
                padding-left: 10px;
                padding-right: 10px;
            }

            &__thead-tr {
                gap: 10px;
            }

            &__thead-th {
                flex: 1 1 0;
                justify-content: center;
                min-width: unset;
                font-size: 18px;

                &--id {
                    width: unset;
                }

                &--time {
                    width: unset;
                }

                &--checkbox {
                    width: unset;
                }
            }

            &__tr {
                gap: 10px;
            }

            &__td {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1 1 0;
                justify-content: center;
                font-size: 16px;
                text-align: center;

                &--id {
                    width: unset;
                }

                &--checkbox {
                    width: unset;
                }
            }

            &__bg {
                padding: 10px;
            }

            &__btns-list {
                justify-content: center;
                gap: 15px;
            }

            &__btn {
                min-width: 130px;
                min-height: 40px;
                font-size: 16px;
            }

            &__input-wrap {
                min-width: 63px;
                display: flex;
                justify-content: flex-start;
            }
        }
    }

    @media screen and (max-width: ($bp_mb - 1px)) {
        .my-stake-table {
            &__body {
                gap: 0;
            }

            &__thead {
                padding: 0;
            }

            &__thead-tr {
                gap: 7px;
            }

            &__thead-th {
                flex: 1 1 0;
                justify-content: center;
                min-width: unset;
                font-size: 16px;

                &--type {
                    display: none;
                }

                &--id {
                    width: unset;
                }

                &--time {
                    width: unset;
                }
            }

            &__tr {
                gap: 7px;
            }

            &__td {
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 50px;
                flex: 1 1 0;
                justify-content: center;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                padding-top: 10px;
                padding-bottom: 10px;

                &--type {
                    display: none;
                }

                &--mobile {
                    display: flex;
                }

                &--id {
                    width: unset;
                    border-left: 1px solid #6B7B98;
                    border-right: 1px solid #6B7B98;
                }
            }

            &__bg {
                padding: 0;
                background-color: unset;
                box-shadow: none;
                backdrop-filter: none;
                border-radius: 0;
                border-top: 1px solid #6B7B98;
            }

            &__pagination {
                justify-content: center;
            }

            &__btn {
                min-width: 96px;
                min-height: 36px;
                padding: 4px 4px;

                font-size: 14px;
                line-height: 17.5px;
            }

            &__input-wrap {
                min-width: 63px;
            }
        }
    }
    
    .card-footer {
        width: fit-content;
        margin-right: 0;
        margin-left: auto;
        color: white;
    }
</style>

<style lang="scss">
    .pagination li.first,
    .pagination li.last {
        display: none;
    }

    .pagination {
        margin-top: 30px;
        color: #6B7B98;
        display: flex;
        gap: 0.5em;
    }

    .pagination a {
        display: flex;
        font-family: 'Tektur';
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        padding: 6px 12px;
    }

    .page-number.active a {
        font-size: 18px;
        background: -webkit-linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .pagination li.previous a {
        content: '';
        // background: url('../../../assets/svg/pArrow.svg?inline');
        background-position: center center;
        height: 100%;
        width: 32px;
        background-repeat: no-repeat;
    }

    .pagination li.next a {
        content: '';
        // background: url('../../../assets/svg/pArrow.svg?inline');
        background-position: center center;
        height: 100%;
        width: 32px;
        background-repeat: no-repeat;
        transform: rotate(180deg);
    }

    .filters {
        width: 275px;

        &__title {
            font: 800 24px Raleway;
            position: relative;
            height: 57px;
            padding: 15px 0;

            &-text {
                color: white;
                position: absolute;
            }

            &-arrow-svg {
                display: none;
                position: absolute;
                right: 15px;
                top: calc(50% - 3px);
            }

            &:before {
                position: absolute;
                content: "";
                background: linear-gradient(270deg, #15257C 37.48%, rgba(21, 37, 124, 0) 99.15%);
                height: 100%;
                right: 0;
                left: -25%;
                top: 0;
            }

            @media screen and (max-width: $bp_mb) {
                font-size: 16px;
                height: 32px;
                padding: 8px 0;
                margin-top: 20px;
                transform: skew(-4deg);

                &-arrow-svg {
                    display: inline-block;
                }
            }
        }

        &__wrapper {
            position: relative;
            color: #BFBFBF;
            font-weight: 500;

            @media screen and (max-width: 1115px) {
                display: none;
            }

            &--opened {
                display: block;
            }

            &--mobi {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 199px;
                padding: 0 1.5em;
                z-index: 2;
                background: linear-gradient(180deg, #1f213a00 0%, #000000 100%), #1F213A;
                overflow-y: auto;

                @media screen and (max-width: $bp_mb) {
                    top: 180px;
                    padding-bottom: 6em;
                    box-shadow: inset 0px 0px 5px 0px #02020466;
                }
            }
        }

        .item {
            display: flex;
            gap: 8px;

            &--row {
                gap: 15px;
                align-items: center;
            }

            &--column {
                flex-direction: column;
            }

            &--mb {
                margin-bottom: 1em;
            }
        }

        .number-input {
            width: 64px;
            height: 40px;
            color: #FFCC13;
            padding: 8px;
            background: transparent;
            border: 1px solid #BFBFBF;
            text-align: center;

            &--empty {
                color: #BFBFBF;
            }
        }

        .tier-block {
            width: 40px;
            height: 40px;
            cursor: pointer;

            &--fighter{
                background: #4488FF;
            }
            &--ultimate{
                background: #33DE98;
            }
            &--epic{
                background: #DB76FF;
            }
            &--legendary{
                background: #FFBB0B;
            }
            &--common{
                background: #C8C8C8;
            }

            &--checked:after {
                content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none"><path d="M2 6L7.25 11L16 1" stroke="black" stroke-width="3"/></svg>');
                display: block;
                width: 22px;
                height: 10px;
                margin: 10px 5px 0 10px;
            }
        }

        &-checkbox {
            font: 400 19px "Tektur";
            color: #BFBFBF;
            margin-bottom: 5px;

            .checkbox-checkmark {
                border: 2px solid #BFBFBF;

                &:after {
                    border-color: black;
                    left: 5px;
                    top: 1px;
                    width: 7px;
                    height: 11px;
                    border-width: 0 4px 4px 0;
                }
            }

            .checkbox-input {
                &:checked ~ .checkbox-checkmark {
                    background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
                }
            }

            &:hover {
                .checkbox-label {
                    color: #FFCC13;
                }

            }
        }

        &-checkbox--staking {
            gap: 0;
            margin-bottom: 0;

            .checkbox-checkmark {
                border: 2px solid #fff;

                &::after {
                    left: 6px;
                    top: 3px;
                }
            }

            .checkbox-input {
                &:checked ~ .checkbox-checkmark {
                    border: 0;
                }
            }
        }

        &-booster {
            font: 400 20px "Tektur";
            cursor: pointer;

            &--active,
            &:hover {
                color: #FFCC13;
            }
        }

        &__haracteristic {
            &-title {
                font: 500 14px "Tektur";
                margin-bottom: 10px;
            }

            .item {
                margin-bottom: 15px;
            }
        }

        &-apply-btns {
            display: flex;
            justify-content: center;
            text-align: center;
            padding-top: 20px;
            gap: 10px;
            z-index: 2;
            bottom: 0px;

            .button--icon-gradient {
                padding: 10px 15px;
            }

            @media screen and (max-width: $bp_mb) {
                position: fixed;
                bottom: 1.5em;
                left: 0;
                width: 100%;
            }
        }

        &-arrow-svg--close {
            display: none;
            position: absolute;
            margin-top: -35px;
            padding-right: 50%;

            @media screen and (max-width: $bp_mb) {
                display: inline-block;
            }
        }
    }
</style>
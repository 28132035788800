var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("paginate", {
    attrs: {
      "page-count": _vm.pageCount,
      "page-range": 3,
      "margin-pages": 2,
      "click-handler": _vm.clickHandler,
      "prev-text":
        "<svg width='9' height='20' viewBox='0 0 9 20' fill='none' xmlns='http://www.w3.org/2000/svg'>\n                <path d='M8 1L2 10L8 19' stroke='#6B7B98' stroke-width='2'/>\n               </svg>",
      "next-text":
        "<svg class='n-arrow' width='9' height='20' viewBox='0 0 9 20' fill='none' xmlns='http://www.w3.org/2000/svg'>\n                <path d='M8 1L2 10L8 19' stroke='#6B7B98' stroke-width='2'/>\n               </svg>",
      "container-class": "pagination",
      "page-link-class": "pagination-page-item",
      "active-class": "pagination-active-item",
      "prev-link-class": "pagination-prev-class",
      "next-link-class": "pagination-next-class",
    },
    model: {
      value: _vm.current,
      callback: function ($$v) {
        _vm.current = $$v
      },
      expression: "current",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
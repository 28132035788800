var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.stakes.length
      ? _c(
          "div",
          { staticClass: "my-stake-table card" },
          [
            _c("UnstakeTable", {
              attrs: {
                rows: _vm.stakes,
                pageCount: _vm.pageCount,
                page: _vm.page,
                selected: _vm.selected,
                pageSize: _vm.pageSize,
              },
              on: {
                "update:page": (value) => (_vm.page = value),
                "update:selected": (value) => (_vm.selected = value),
              },
            }),
            _c("div", { staticClass: "my-stake-table__footer-wrap" }, [
              _c("ul", { staticClass: "my-stake-table__btns-list" }, [
                _c(
                  "li",
                  [
                    _c(
                      "btn",
                      {
                        staticClass: "my-stake-table__btn button--gradient",
                        on: {
                          click: function ($event) {
                            _vm.selected = []
                          },
                        },
                      },
                      [_vm._v(" CANCEL SELECTION ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "btn",
                      {
                        staticClass: "my-stake-table__btn button--gradient",
                        on: { click: _vm.unstake },
                      },
                      [_vm._v(" UNSTAKE SELECTED ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        )
      : _c("p", { staticClass: "my-stake-table__no-data" }, [
          _vm._v("You don’t have anything that can be unstaked"),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
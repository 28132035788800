<template>
    <paginate
        v-model="current"
        :page-count="pageCount"
        :page-range="3"
        :margin-pages="2"
        :click-handler="clickHandler"
        prev-text="<svg width='9' height='20' viewBox='0 0 9 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M8 1L2 10L8 19' stroke='#6B7B98' stroke-width='2'/>
                   </svg>"
        next-text="<svg class='n-arrow' width='9' height='20' viewBox='0 0 9 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M8 1L2 10L8 19' stroke='#6B7B98' stroke-width='2'/>
                   </svg>"
        :container-class="'pagination'"
        :page-link-class="'pagination-page-item'"
        :active-class="'pagination-active-item'"
        :prev-link-class="'pagination-prev-class'"
        :next-link-class="'pagination-next-class'"
    >
    </paginate>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        currentPage: {type: Number, default: 1},
        pageCount: {type: Number, default: 1}
    },
    computed: {
        current: {
            set() {},
            get() {
                return this.currentPage;
            },
        }
    },
    data() {
        return {
            t1: ``,
            test: `<BaseIcon name='pArrow' class="p-arrow"/>`,
            tt: require(`../../assets/svg/pArrow.svg`),
        }
    },
    methods: {
        clickHandler(page) {
            this.$emit('clickHandler', page);
        },
    }
}
</script>

<style lang="scss">
    .pagination {
        width: fit-content;
        color: #6B7B98;
        display: flex;
        gap: 0.5em;

        &-page-item,
        &-prev-class,
        &-next-class {
            padding: 0.5em;
            user-select: none;
            font-weight: 600;

        }
        &-active-item a{
            color: #FFCC13;
            //background-color: white;
        }

        .disabled a{
            color: gray;
            border-color: gray;
            cursor: default;
        }
    }
</style>
